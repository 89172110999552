import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'react-admin';
import './form_section.scss'

const FormSection = ({ label, description, icon, children, className, resource, record, basePath, ...rest }) =>

    <section className="form_section">

        <header className="form_section__header ">
            <h3>{label}</h3>
            <p>{description}</p>
        </header>
        <div className="form_section__content">

            {React.Children.map(children, input => input && (
                <div key={input.props.source} style={input.props.style} className={`aor-input-${input.props.source}`}>
                    {
                        input && React.isValidElement(input) ? React.cloneElement(input, {
                            context: 'content',
                            resource,
                            record,
                            basePath
                        }) : null
                    }
                </div>
            ))}

        </div>

    </section>;


FormSection.propTypes = {
    label: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.element,
    // children: PropTypes.arrayOf(PropTypes.element).isRequired, // Todo fix array of children..
};

export default FormSection;