
import React from 'react';
import { Datagrid } from 'react-admin';
import { withRouter } from 'react-router-dom'
import './datagrid.scss'

 const rowClick = (id, basePath, record) => {
//     var ids = props.ids;
//     var url = props.basePath+'/'+ids[index];
//     history.push(url);
 }

const DatagridX = ({ children, options, history, staticContext, ...props }) =>  {
    var onRowSelection = props.onRowSelection ? props.onRowSelection : rowClick;
    onRowSelection = onRowSelection.bind(null, history, props)
    // rowOptions={{ selectable: true }}
    return (
        <div className="datagrid">
            <Datagrid {...props} rowClick="edit" options={ {onRowSelection: onRowSelection, multiSelectable: false, className: 'Datagrid' } }>
                {children}
            </Datagrid>
        </div>
    )
}
export default withRouter(DatagridX);


