import React from "react";
import {
  TextField,
  Filter,
  List,
  TextInput
} from 'react-admin';

import { Datagrid } from "components/datagrid";

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" />
  </Filter>
)

export default props => (
  <List  sort={{ field: "name", order: "DESC" }} {...props} filters={<ListFilter />}>
    <Datagrid>
      <TextField source="name" />
    </Datagrid>
  </List>
);

