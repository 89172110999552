import React, { Component }  from 'react';
import queryString from 'query-string';

const ssoUrl = (route) => {
  const params = {
    returnUrl: process.env.REACT_APP_ADS_URL + '/#'
  }
  return `${process.env.REACT_APP_SSO_URL}/${process.env.REACT_APP_ADS_SSO_CLIENT_ID}/${route}?${queryString.stringify(params)}`
}

class AppLogoutPage extends Component {

  componentDidMount = () => {
    const { REACT_APP_SSO_URL, REACT_APP_ADS_SSO_CLIENT_ID, REACT_APP_ADS_URL } = process.env
    if ( REACT_APP_SSO_URL && REACT_APP_ADS_SSO_CLIENT_ID && REACT_APP_ADS_URL )
    {
      window.location.href = ssoUrl('sso_logout')
    }
  }

  render() {
      return <p>Logout</p>;
  }
}

export default AppLogoutPage;
