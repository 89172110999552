import React from 'react';
import {
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    Filter,
    List,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ChipField
 } from 'react-admin';


import { Datagrid } from 'components/datagrid';


const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" />
    <ReferenceInput source="provider_id" reference="providers">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput label="Expired" source="is_expired" />
  </Filter>
)


export default (props) => (
  <List filters={<ListFilter />}  {...props} perPage={20} sort={{ field: 'title', order: 'DESC' }}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceArrayField reference="locations" source="location_ids">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField reference="categories" source="category_ids">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="expiration_date" />
    </Datagrid>
  </List>
);

