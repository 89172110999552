
import React from 'react';
import {
    LongTextInput,
    FileInput,
    ImageField,
    ImageInput,
    SimpleForm,
    Create,
    Edit
} from 'react-admin';

import { TextInput } from 'components/text_input';
import { FileField  } from 'components/file_field';
import { SizeField } from 'components/size_field';
import { Toolbar } from 'components/toolbar'
import { FormSection } from 'components/form_section'

const CompanyForm = (props) => (
    <SimpleForm toolbar={Toolbar(props)}>
        <FormSection label="Company" description="Basic contact information abount the company.">
             <FileInput multiple={false} source="pictures"  accept="image/*" placeholder={<p>Släpp en fil här, eller klick för att välja filer från din dator.</p>} >
                <FileField source="logo"  />
            </FileInput>
            {/* <ImageInput multiple={false} source="pictures"  accept="image/*"  placeholder={<p>Släpp en fil här, eller klick för att välja filer från din dator.</p>} >
                <ImageField source="logo"  />
            </ImageInput> */}
            <TextInput  source="name" />
            <TextInput  source="email" />
            <TextInput  source="url" />
            <LongTextInput  source="info" />
        </FormSection>
    </SimpleForm>
);



export const CompanyCreate = (props) => (
    <Create {...props}>
        {CompanyForm({...props, })}
    </Create>
);

export const CompanyEdit = (props) => (
    <Edit {...props} >
        {CompanyForm({...props, }) }
    </Edit>
);

export default CompanyForm;
