import React from 'react';
import {
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    LongTextInput,
    AutocompleteInput,
    Edit,
    Create,
    SimpleForm
 } from 'react-admin';

import { RichTextInput } from 'components/rich_text_input';
import { AutocompleteArrayInput } from 'react-admin';
// import { AssetSelectInput } from 'components/asset_select_input';
import { FormSection } from 'components/form_section';
import { TextInput } from 'components/text_input';
import { DateInput } from 'components/date_input';
import { AdToolbar } from 'components/toolbar'



export const AdForm = (props) => (
    <SimpleForm toolbar={AdToolbar(props)}>
        <FormSection label="Summary"  >
            <TextInput  source="slug" />
            <TextInput  source="title" />
            <LongTextInput  source="summary"  />
        </FormSection>
        <FormSection label="Content" >
            <RichTextInput  source="body" />
        </FormSection>
        <FormSection label="Dates" >
            <DateInput  source="publication_date" />
            <DateInput  source="expiration_date" />
        </FormSection>
        <FormSection label="Company">
            <ReferenceInput  sort={{ field: 'name', order: 'ASC' }}  source="company_id" reference="companies" allowEmpty>
                <AutocompleteInput autoComplete="off" optionText="label" />
            </ReferenceInput>
            <ReferenceInput sort={{ field: 'name', order: 'ASC' }}   source="recruiter_id" reference="companies" allowEmpty>
                <AutocompleteInput optionText="label" />
            </ReferenceInput>
            <ReferenceInput  source="provider_id" reference="providers" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </FormSection>
        <FormSection  label="Meta">
            <ReferenceArrayInput  source="location_ids" reference="locations" allowEmpty perpage={100000} filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteArrayInput optionText="name" optionId="id"/>
            </ReferenceArrayInput>
            <ReferenceArrayInput  source="category_ids" reference="categories" allowEmpty perpage={100000} filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteArrayInput optionText="pathString" optionId="id"/>
            </ReferenceArrayInput>
            <TextInput  source="uuid" disabled={true} />
        </FormSection>
        <FormSection  label="References">
            <TextInput  source="url" />
            <TextInput  source="application_url" />
            <TextInput  source="application_email" />
        </FormSection>
    </SimpleForm>
);

/**
 *
<FormSection  description="Ladda upp eventuella bilagor som användarna ska kunna ta del av." className="FileInput">
            <TextInput  source="logo" />
            <ReferenceArrayInput  source="asset_ids" reference="assets" allowEmpty>
                <AssetSelectInput optionText="name" optionId="id"/>
            </ReferenceArrayInput>
        </FormSection>

    } props
 */
export const AdCreate = (props) => (
    <Create {...props}>
        {AdForm({...props, })}
    </Create>
);

export const AdEdit = (props) => (
    <Edit {...props} >
        {AdForm({...props, }) }
    </Edit>
);

export default AdForm;
