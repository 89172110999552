import * as Cookies from "js-cookie";
import { decode } from "jsonwebtoken"

const opt = { path: '/' }
const cookieName = 'kr-ads'

export const rpc_headers = (headers) => {
  return new Headers({ "Content-Type": "application/json", ...headers, ...authorization() })
}

export const authorization = () => {
  const token = Cookies.get(cookieName, opt);
  return { 'Authorization': `Bearer ${token}` }
}
export const  get = () => {
  return Cookies.get(cookieName, opt)
}
export const  set = (data) => Cookies.set(cookieName, data, opt)
export const  remove = () => Cookies.remove(cookieName, opt)
export const isAdmin = () => {
  const jwtstring = get()
  const jwt = decode(jwtstring)
  return jwt && jwt.role_id === "kb_role_adm"
}

