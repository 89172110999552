import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { addField } from 'react-admin';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rich_text_input.scss';

const toolbar = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    textAlign: {
      inDropdown: false,
      options: ['left', 'center', 'right', 'justify'],
    },
    link: {
      inDropdown: false,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
    },
    history: {
      inDropdown: false,
      options: ['undo', 'redo'],
    },
  }

class RichTextInput extends Component {
    constructor(props) {
        super(props);

        const contentBlock = htmlToDraft(props.input.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }

        this.onChange = (editorState) => this.setState({editorState});
      }

    //   componentWillReceiveProps(nextProps) {
    //     if (nextProps.input)
    //     {
    //         if (nextProps.input.value !== this.props.input.value) {
    //             //this.quill.pasteHTML(nextProps.input.value);
    //             const blocksFromHtml = htmlToDraft(nextProps.input.value);
    //             const { contentBlocks, entityMap } = blocksFromHtml;
    //             const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    //             const editorState = EditorState.createWithContent(contentState);


    //         }
    //     }
    // }

      onEditorStateChange = (editorState) => {

        this.setState({
          editorState,
        });
      };

      onContentStateChange = (rawContentState) => {
        const markup = draftToHtml(rawContentState);
        if (this.props.input && this.props.input.onChange)
        {
            this.props.input.onChange(markup);
        }
      }

      render() {
        const { editorState } = this.state;
        return (
            <Editor
                toolbar={toolbar}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="rich_text_input"
                editorClassName="rich_text_input--editor"
                onEditorStateChange={this.onEditorStateChange}
                onContentStateChange={this.onContentStateChange}
                />
        );
      }
}

RichTextInput.propTypes = {
    addLabel: PropTypes.bool.isRequired,
    input: PropTypes.object,
    label: PropTypes.string,
    options: PropTypes.object,
    source: PropTypes.string,
    toolbar: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
    ]),
};

RichTextInput.defaultProps = {
    addLabel: true,
    options: {},
    record: {},
    toolbar: true,
};

export default addField(RichTextInput);