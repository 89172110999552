
import React from 'react';
import { TextInput } from 'react-admin';
import './text_input.scss'

const TextInputX = (props) => (
    <div className="text_input">
        <TextInput {...props} ></TextInput>
    </div>
);
TextInputX.defaultProps = {
    disabled: false
}

export default TextInputX;