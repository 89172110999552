    import React from 'react';
import { Route } from 'react-router-dom';


import { createHashHistory } from 'history';
import { fetchUtils, Admin, Resource, Delete } from 'react-admin';
import jsonServerRestClient from './jsonServerRestClient';
import { createMuiTheme } from '@material-ui/core/styles';
import { AdList,  AdEdit , AdCreate } from 'containers/ads';
import { ProviderList,  ProviderEdit , ProviderCreate} from 'containers/providers';
import { CompanyList,  CompanyEdit , CompanyCreate} from 'containers/companies';
import { UserList,  UserEdit , UserCreate} from 'containers/users';
import { LocationList,  LocationEdit , LocationCreate } from 'containers/locations';
// import { FeedList,  FeedEdit , FeedCreate } from 'containers/feeds';
// import { MapList,  MapEdit , MapCreate } from 'containers/map';
// import { AssetList,  AssetEdit , AssetCreate } from 'containers/assets';
import { CategoryList,  CategoryEdit , CategoryCreate } from 'containers/categories';
import AppLogout from './app_logout';
import AppLogoutButton from './app_logout_button';

import authProvider from 'authProvider';
import addUploadFeature from 'addUploadCapabilities';
import './app.scss'

// import { Login } from 'components/login';
import { AppBar } from 'components/app_bar';

import AppMenu from './app_menu';
import AppLogin from './app_login';
import AppSsoCallback from './app_sso_callback'
import * as token from 'app/token'

import swedishMessages from './app_i18n';


import appTheme from './_app_theme'
import * as Icons from 'utils/icons';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const jwt = token.get();
    options.headers.set('Authorization', `Bearer ${jwt}`);

    return fetchUtils.fetchJson(url, options);
}

const i18nProvider = locale => messages[locale];
const messages = {
    'no': swedishMessages,
};
const api = process.env.REACT_APP_API_BASE
const muiTheme = createMuiTheme(appTheme)
const client = jsonServerRestClient(api, httpClient)
const uploadCapableClient = addUploadFeature(client);

const MyRoutes = [
  <Route
        path="/callback"
        component={AppSsoCallback}
        noLayout
  />,
  <Route
        path="/logout"
        component={AppLogout}
        noLayout
  />
]

const App = () => (
  <Admin
    logoutButton={AppLogoutButton}
    loginPage={AppLogin}
    customRoutes={MyRoutes}
    locale="no"
    i18nProvider={i18nProvider}
    menu={AppMenu}
    theme={muiTheme}
    title={<AppBar />}
    authProvider={authProvider}
    dataProvider={uploadCapableClient}
  >

    <Resource name="ads"
      options={{ label: 'Ads' }}
      list={AdList}
      edit={AdEdit}
      create={AdCreate}

      icon={Icons.DescriptionIcon}/>

      <Resource name="companies"
      options={{ label: 'Companies' }}
      list={CompanyList}
      edit={CompanyEdit}
      create={CompanyCreate}

      icon={Icons.DomainIcon}/>

{/*
    <Resource name="assets"
      options={{ label: 'Assets' }}
      list={AssetList}
      edit={AssetEdit}
      create={AssetCreate}

      icon={Icons.AssetIcon}
      /> */}

    <Resource name="categories"
      options={{ label: 'Categories' }}
      list={CategoryList}
      edit={CategoryEdit}
      create={CategoryCreate}

      icon={Icons.LabelIcon}/>

    <Resource name="locations"
      options={{ label: 'Locations' }}
      list={LocationList}
      edit={LocationEdit}
      create={LocationCreate}

      icon={Icons.LocationOnIcon}/>

      <Resource name="providers"
      options={{ label: 'Provider' }}
      list={ProviderList}
      edit={ProviderEdit}
      create={ProviderCreate}

      icon={Icons.Create}/>

    {/* <Resource name="feeds"
      options={{ label: 'Feeds' }}
      list={FeedList}
      edit={FeedEdit}
      create={FeedCreate}

      icon={Icons.RssFeedIcon}/> */}

    <Resource name="users"
      options={{ label: 'Users' }}
      list={UserList}
      edit={UserEdit}
      create={UserCreate}

      icon={Icons.UserIcon}/>
      {/*
      <Resource name="maps"
      options={{ label: 'Mappers' }}
      list={MapList}
      edit={MapEdit}
      create={MapCreate}

      icon={Icons.MergeType}/> */}

  </Admin>
);

export default App;