import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';

import * as Icons from 'utils/icons';
import './back_button.scss'

const BackButton = ({ basePath = '', label = 'ra.action.list', translate }) =>
<Link role="button" className="button button__flat back_button" to={basePath}>
    <Icons.ArrowBack />
</Link>

BackButton.propTypes = {
    basePath: PropTypes.string,
    translate: PropTypes.func.isRequired,
};
export default translate(BackButton);
