import React, { Component } from 'react';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import './file_field.scss'
import * as token from 'app/token'

class FileField extends Component {

state = {
    percent: 0,
    pending: false,
    src: ""
};

onProgress = (e) =>
{
    this.setState({percent: e.percent, pending: true });
}

render() {
    const { record } = this.props;

    if (record)
    {
        var file = record.rawFile;
        if (file)
        {
            if (!this.state.pending && !file.pending && !file.complete)
            {
                const jwt = token.get('token');
                const headers = new Headers();
                headers.append("Authorization", `Bearer ${jwt}`);

                file.pending = true;
                fetch(process.env.REACT_APP_API_BASE + '/gcs?filename='+encodeURIComponent(file.name), {
                    method: 'get',
                    headers: headers
                }).then( (response) => response.json()
                ).then( (json) => {
                    const { signedUrl, path, url } = json
                    fetch(signedUrl, {
                        method: 'PUT',
                        headers: {"Content-Type": "image/png"},
                        body: file
                    }).then( () => {
                        file.complete = true;
                        file.pending = false;
                        file.info = {
                            location: path,
                            size: file.size,
                            mime: file.type,
                            name: file.name,
                        }
                        record.location = path;
                        this.setState({percent: 0, pending: false, src: url});
                    }).catch( err => {
                        console.error("err")
                        console.error(err)
                    })
                });
            }
        }
    }

    return (
        <div className="file_field">
        {
            this.state.src ?
            <img src={this.state.src} alt="" />
            :
            record.location ?
            <img src={record.location} alt="" />
            : null
        }
        { this.state.pending ? <LinearProgress  mode="determinate" value={this.state.percent} min={0} max={100}/> : null }
        </div>
        );
    }

}

FileField.propTypes = {
    elStyle: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    src: PropTypes.string,
    title: PropTypes.string,
};

FileField.defaultProps = {
    elStyle: { display: 'inline-block' },
};

export default FileField;