import get from 'lodash.get';
import request from 'superagent';

const addUploadCapabilities = requestHandler => (type, resource, params) => {
    if (type === '_UPDATE' && resource !== 'posts') {
        if (params.data.pictures && params.data.pictures.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.pictures.filter(p => !(p.rawFile instanceof File));
            const newPictures = params.data.pictures.filter(p => p.rawFile instanceof File);

            return Promise.all(newPictures)
                .then(files => files.map(file => {
                    console.log("upload::", file)
                }))
                .then(uploadedFiles => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        pictures: [...uploadedFiles, ...formerPictures],
                    },
                }));
        }
    }

    return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
