import React from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';


const MyMenu = ({ resources, onMenuTap, logout, ...props }) => {

    if (!resources) return null

    return (
        <div className="app_menu">

            { resources.map(resource => {
                return <MenuItemLink
                key={resource.name}
                leftIcon={<resource.icon />}
                onClick={onMenuTap}
                className="app_menu__item"
                primaryText={resource.options.label}
                to={`/${resource.name}`}
                />
            })}

        </div>
    )
}

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
