
import React from 'react';
import {
    LongTextInput,
    SimpleForm,
    Create,
    Edit
} from 'react-admin';

import { TextInput } from 'components/text_input';
import { FormSection } from 'components/form_section';
import { Toolbar } from 'components/toolbar'

const ProviderForm = (props) => (
    <SimpleForm  toolbar={Toolbar(props)}>
        <FormSection  description="Contact details about the provider.">
            <TextInput  source="name" />
            <TextInput  source="email" />
            <TextInput  source="phone" />
            <TextInput  source="url" />
            <LongTextInput  source="info" />
        </FormSection>
    </SimpleForm>
);


export const ProviderCreate = (props) => (
    <Create {...props}>
        {ProviderForm({...props, })}
    </Create>
);

export const ProviderEdit = (props) => {
    return (
    <Edit {...props} >
        {ProviderForm({...props, title: "Update provider"}) }
    </Edit>
);}

export default ProviderForm;
