
import React from 'react';
import { DateInput as DateInputAor } from 'react-admin';
import { dateFormatter, dateParser } from 'utils/date_converter'

const DateInput = (props) => {
    return (
    <DateInputAor {...props} ></DateInputAor>
    )
};

export default DateInput;

