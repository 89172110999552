
import React from 'react';
import {
    LongTextInput,
    TextField,
    ReferenceManyField,
    SimpleForm,
    Create,
    Edit
} from 'react-admin';

import { TextInput } from 'components/text_input';
import { Toolbar } from 'components/toolbar'
import { Datagrid } from 'components/datagrid';
import { FormSection } from 'components/form_section'

const LocationForm = (props) => (
    <SimpleForm toolbar={Toolbar(props)}>
        <FormSection label="Location" description="Basic information about the region.">
            <TextInput  source="name" />
            <TextInput  source="alias" />
            <LongTextInput  source="description" />
        </FormSection>
    </SimpleForm>
);


export const LocationEdit = (props) => {
    return (
    <Edit {...props} >
        {LocationForm(props)}
    </Edit>
);}

export const LocationCreate = (props) => {
    return (
    <Create {...props} >
        {LocationForm(props)}
    </Create>
);}

export default LocationForm;
