import React from 'react';
import PropTypes from 'prop-types';

const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const pretty_bytes = (num) => {

    if (typeof num === 'undefined')
    {
        num = 0
    }

    if (typeof num === 'string')
    {
        num = parseInt(num, 10)
    }

	if (!Number.isFinite(num)) {
        return 'NaN'
	}

	const neg = num < 0;

	if (neg) {
		num = -num;
	}

	if (num < 1) {
		return (neg ? '-' : '') + num + ' B';
	}

	const exponent = Math.min(Math.floor(Math.log10(num) / 3), UNITS.length - 1);
	const numStr = Number((num / Math.pow(1000, exponent)).toPrecision(3));
	const unit = UNITS[exponent];

	return (neg ? '-' : '') + numStr + ' ' + unit;
};

const PrettyBytes = ({ value }) => <span className="PrettyBytes">{pretty_bytes(value)}</span>

PrettyBytes.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

export default PrettyBytes;