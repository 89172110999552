import React from 'react';
import { SimpleForm, DeleteButton, SaveButton, Toolbar as RaToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { RssFeedIcon } from  'utils/icons'


import { BackButton } from 'components/back_button';
import './toolbar.scss'

const openPreview = (uri) => {
        if (uri)
        {
                window.open(uri[0], '_blank')
        }
}

const Empty = ({children}) =>
  <span>
    {children}
  </span>

const Preview = ({...props}) =>
{
return (
  <Button onClick={() => openPreview(props.record.uri)}>
    <RssFeedIcon />
    <span>Preview</span>
  </Button>
)
}

export const AdToolbar = ({hasList, hasEdit, hasShow, hasCreate, ...props}) =>
  <RaToolbar {...props} className="toolbar">
    <BackButton basePath={props.basePath} />
    <Empty><Typography variant="headline" >{props.title}</Typography></Empty>
    <DeleteButton />
    <Preview />
    <SaveButton submitOnEnter />
  </RaToolbar>;




export default ({hasList, hasEdit, hasShow, hasCreate, ...props}) =>
  <RaToolbar {...props} className="toolbar">
    <BackButton basePath={props.basePath} />
    <Empty><Typography variant="headline" >{props.title}</Typography></Empty>
    <DeleteButton />
    {props.buttons}
    <SaveButton submitOnEnter />
  </RaToolbar>;



