import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import * as token from 'app/token'
import decode from "jsonwebtoken/decode";

// import { history } from ''

const { REACT_APP_SSO_URL, REACT_APP_ADS_SSO_CLIENT_ID, REACT_APP_ADS_URL } = process.env

export default (type, params) => {
    if (type === AUTH_LOGOUT) {
      token.remove()
      return Promise.resolve();
    }

    if (type === AUTH_LOGIN) {
      // Noop
    }
    if (type === AUTH_CHECK) {
      const jwtstring = token.get()

      const jwt = decode(jwtstring);
      if (jwt)
      {
        const isAdmin = (jwt && jwt.role_id === 'kb_role_adm')
        return  isAdmin ? Promise.resolve() : Promise.reject({ redirectTo: '/logout' });
      }
      else
      {
        return  Promise.reject({ redirectTo: '/login' });
      }
    }
    if (type === AUTH_ERROR) {
      const { status } = params;
      if (status === 401 || status === 403) {
        token.remove()
        return Promise.reject();
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  };
