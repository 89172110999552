
import React from 'react';
import {
    TextField,
    LongTextInput,
    ReferenceManyField,
    SimpleForm,
    Create,
    Edit
} from 'react-admin';

import { FormSection } from 'components/form_section';
import { TextInput } from 'components/text_input';
import { Datagrid } from 'components/datagrid';
import { Toolbar } from 'components/toolbar'

const CategoryForm = (props) => (
    <SimpleForm title={props.title} toolbar={Toolbar(props)}>
        <FormSection  description="Category details">
            <TextInput  source="name" />
            <TextInput  source="alias" />
            <LongTextInput  source="description" />
        </FormSection>
    </SimpleForm>
);


export const CategoryCreate = (props) => (
    <Create {...props}>
        {CategoryForm({...props, })}
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit {...props} >
        {CategoryForm({...props, }) }
    </Edit>
);

export default CategoryForm;
