import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FieldTitle from 'ra-core/lib/util/FieldTitle';
import PrettyBytes from 'utils/pretty_bytes';

const SizeField = ({ input, label, resource, source, elStyle }) => {
    if (!input)
        return null

    return (<TextField
        value={<PrettyBytes value={input.value} />}
        floatingLabelText={<FieldTitle label={label} source={source} resource={resource} />}
        style={elStyle}
        disabled
    />)
}

SizeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    elStyle: PropTypes.object,
    input: PropTypes.object,
    addField: PropTypes.bool.isRequired,
};

SizeField.defaultProps = {
    addField: true,
}

export default SizeField;