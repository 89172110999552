
import React from 'react';
import {
    SimpleForm,
    Create,
    Edit
} from 'react-admin';

import Typography from '@material-ui/core/Typography';

import { FormSection } from 'components/form_section';
import { TextInput } from 'components/text_input';
import { Toolbar } from 'components/toolbar'

const FeedFieldBase = (record) => process.env.REACT_APP_API_BASE.replace(/:\/\//, `://${record['name']}:${record['remember_token']}@`)
const FeedFieldUrl = (record) => `${FeedFieldBase(record)}/export`
const FeedField = ({ record }) =>
  <Typography>
    <br/>
    <span>Feed URL:</span>
    <a href={FeedFieldUrl(record)}>{FeedFieldUrl(record)}</a>
  </Typography>


const UserForm = (props) => (
  <SimpleForm toolbar={Toolbar(props)}>
    <FormSection  description="User access details.">
      <TextInput source="name" label="Username"/>
      <TextInput source="email" />
      {/* <TextInput source="password" /> */}
      <TextInput source="remember_token" label="User API token"  />
      <FeedField />
    </FormSection>
  </SimpleForm>
);

export const UserCreate = (props) => (
  <Create {...props}>
    {UserForm({...props, })}
  </Create>
);

export const UserEdit = (props) => {
    return (
      <Edit {...props} >
        {UserForm({...props, }) }
      </Edit>
);}

export default UserForm;
