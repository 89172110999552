

import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';

const settings = {
    '$body-textcolor': '#333333',
    '$body-bordercolor': '#d8d8d8',
    '$primary-action-color': '#DD3834',
    '$secondary-action-color': '#DD3834',
}

export default {

    borderRadius: 0,
    palette: {
        accent1Color: settings['$secondary-action-color'],
        borderColor: settings['$body-bordercolor'],
        contrastThreshold: 3,
        error: red,
        primary: {
            light: '#757ce8',
            main: '#568ebf',
            dark: '#3f5b73',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#003762',
            dark: '#ba000d',
            contrastText: '#fff',
          },
        textColor: settings['$body-textcolor'],
        tonalOffset: 0.2,
    },
    overrides: {
        MuiFormControl: {
            root: {
                minWidth: '50%',
            },
        },
    },
    datePicker: {
        headerColor: settings['$primary-action-color'],
        selectColor: settings['$primary-action-color'],
    },
};
